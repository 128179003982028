import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../redux/store";
import Wallet from "../cardano/wallet"

import { Menu, Close, Add, Person } from "@mui/icons-material";
import Particles from 'react-tsparticles';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { availableWallets, disconnectCardanoWallet, connectCardanoWallet } from "../redux/apis/walletApi";
import { useSetUserMutation } from "../redux/apis/userApi";
import FAQ from '../components/FAQ';

const cardanoWallets = {
  nami: {
    title: "Nami",
    image: "https://streamkey.io/images/wallets/nami.png",
    url: "https://namiwallet.io",
  },
  eternl: {
    title: "Eternl",
    image: "https://streamkey.io/images/wallets/eternl.png",
    url: "https://ccvault.io",
  },
  flint: {
    title: "Vespr",
    image: "https://streamkey.io/api/content/uploads/addr1qx5fx5wg0scahca67s3eh8c5suyqaa0jppecrztqd80ru6f7fr59rddrj47q0c206dcgzqhcakr5t3crw04y5n8522zq8caymk/vespr_1734594343373158468.png",
    url: "https://vespr.xyz",
  },
  gerowallet: {
    title: "Gero",
    image: "https://streamkey.io/images/wallets/gero.png",
    url: "https://gerowallet.io",
  },
  typhon: {
    title: "Typhon",
    image: "https://streamkey.io/images/wallets/typhon.png",
    url: "https://typhonwallet.io"
  },
  begin: {
    title: "Begin",
    image: "https://streamkey.io/images/wallets/begin.png",
    url: "https://begin.is",
  },
};

const Layout = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showCardanoWallets, setShowCardanoWallets] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);

  const [menuShow, setMenuShow] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);

  const state_wallet = useAppSelector((state) => state.walletState);
  const state_user = useAppSelector((state) => state.userState);

  const [setUser] = useSetUserMutation();

  const handleCreateClick = () => {
    if (!state_wallet.connected) {
      toast.error("Connect Wallet First!");
      return;
    }
    navigate("/create");
  };

  const hideWallets = () => {
    setShowCardanoWallets(false);
  };

  const onclick_connect_wallet = () => {
    console.log("onclick_connect_wallet_state_wallet:", state_wallet);
    if (!state_wallet.connected) {
      dispatch(availableWallets((res) => {
        console.log("onclick_connect_wallet_availableWallets:", res.wallets);
        setShowCardanoWallets(res.wallets);
      }));
    }
    else {
      if (state_wallet.connected) {
        dispatch(disconnectCardanoWallet(selectedWallet, (res) => {
          Wallet.restoreProvider();
        }));
      }
      navigate("/");
    }
  };

  const connect_CardanoWallet = async (wallet_name) => {
    setShowCardanoWallets(false);
    dispatch(connectCardanoWallet(wallet_name, async (res) => {
      if (res.success) {
        setSelectedWallet(wallet_name);

        // Get the actual wallet instance to check if it's a connector
        const walletInstance = window.cardano;
        const isConnector = walletInstance?._providerName && walletInstance._providerName !== wallet_name;

        // Create form data with additional wallet info
        const formdata = new FormData();
        formdata.append("address", res.data.address);
        formdata.append("wallet_provider", wallet_name);
        if (isConnector) {
          formdata.append("connected_through", walletInstance._providerName);
        }

        setUser(formdata);
      } else {
        toast.error(res.msg || "Failed to connect wallet");
      }
    }));
  };

  const ref = useRef();

  useEffect(() => {
    const initializeWallet = async () => {
        const storedWallet = localStorage.getItem("wallet");
        if (storedWallet) {
            try {
                // Parse the stored wallet info
                const walletInfo = JSON.parse(storedWallet);
                const walletName = typeof walletInfo === 'string' ? walletInfo : walletInfo.wallet;
                
                connect_CardanoWallet(walletName);
            } catch (error) {
                console.error('Failed to initialize wallet:', error);
                localStorage.removeItem("wallet");
            }
        }
    };

    initializeWallet();
  }, []);


  return (
    <div className="relative">
      {/* <Box sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: 'transparent',
      }}>
        <Particles
          id={'1'}
          options={{
            fpsLimit: 120,
            background: {
              color: 'transparent'
            },
            particles: {
              color: { value: '#ffffff' },
              move: {
                direction: 'none',
                enable: true,
                outModes: 'out',
                random: false,
                speed: 0.2,
                straight: false
              },
              number: { density: { enable: true, area: 800 }, value: 50 },
              opacity: {
                value: 0.3
              },
              shape: {
                type: 'images',
                images: [
                  {
                    src: './images/circle_7.png'
                  },
                  {
                    src: './images/circle_15.png'
                  },
                  {
                    src: './images/circle_33.png'
                  },
                  {
                    src: './images/circle_67.png'
                  },
                  {
                    src: './images/circle_99.png'
                  },
                ]
              },
              size: {
                value: { min: 10, max: 30 }
              },
              line_linked: {
                enable: true,
                distance: 200,
                color: '#ffffff',
                opacity: 0.3,
                width: 2,
              },
            }
          }}
        />
      </Box> */}
      <nav>
        <div className="fixed top-0 left-0 w-full h-[70px] bg-white/10 backdrop-blur-sm flex items-center justify-between px-[20px] z-50">
          <div className="flex items-center gap-[40px]">
            <div className="flex text-4xl hover:cursor-pointer z-0" onClick={(e) => { navigate("/") }}>
              <img src="/images/stk_logo.png" alt="" className="h-[35px] lg:h-[40px] object-contain" />
            </div>
          </div>
          <div className="hidden md:flex items-center gap-[20px]">
            <button
              onClick={() => setShowFAQ(true)}
              className="text-[15px] md:text-[20px] bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-500 hover:to-purple-500 rounded-full px-6 py-2.5 font-medium text-white transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg hover:shadow-blue-500/25 active:scale-[0.98] border border-white/20"
            >
              FAQ
            </button>
            <div className="text-[15px] md:text-[20px] bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-500 hover:to-purple-500 rounded-full px-6 py-2.5 font-medium text-white transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg hover:shadow-blue-500/25 active:scale-[0.98] border border-white/20" 
              onClick={() => onclick_connect_wallet()}>
              {!state_wallet.connected
                  ? (state_wallet.loading ? "Connecting..." : "Connect Wallet")
                  : state_wallet.address.slice(0, 5) + "..." +
                  state_wallet.address.slice(state_wallet.address.length - 3)}
            </div>
          </div>
          <div className="flex md:hidden items-center gap-[20px]">
            <div className="text-[15px] bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-500 hover:to-purple-500 rounded-full px-4 py-2 font-medium text-white transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg hover:shadow-blue-500/25 active:scale-[0.98] border border-white/20" 
              onClick={() => onclick_connect_wallet()}>
              {!state_wallet.connected
                  ? (state_wallet.loading ? "..." : "Connect")
                  : state_wallet.address.slice(0, 4) + "..."}
            </div>
            <Menu className="text-white text-3xl cursor-pointer" onClick={() => setMenuShow(!menuShow)} />
          </div>
        </div>
      </nav>
      <div className="w-full h-auto flex flex-col gap-[10px] lg:gap-[60px] pb-[100px] lg:pb-[60px] bg-main_bg">
        <div className="relative w-full min-h-[100vh] flex flex-col gap-[20px] items-center px-[10px] z-0 pt-[120px] lg:pt-[100px]">
          <Outlet />
          
          <a 
            href="https://nfthor.io/collections"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full px-4 lg:px-[200px] hover:opacity-100 transition-all duration-500 mt-[10px] group"
          >
            <div className="relative group">
                <div className="absolute -inset-1 bg-gradient-to-r from-aqua-400 via-blue-300 to-white opacity-70 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-gradient rounded-xl blur-lg"></div>
                <div className="relative">
                    <img 
                        src="/images/ad-freenft-lrg.png"
                        alt="NFThor Marketplace"
                        className="w-full h-auto rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 transform group-hover:scale-[1.01] duration-500 border-2 border-white/20"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                </div>
                <p className="text-center text-white/90 mt-6 mb-2 text-xl font-medium font-montserrat tracking-wide px-4 py-2 bg-black/20 backdrop-blur-sm rounded-lg mx-auto max-w-2xl">
                  Explore exciting NFT collections on a community built decentralized marketplace
                </p>
            </div>
          </a>
        </div>
      </div>
      <nav>
        <div id="footer" className="absolute bottom-0 left-0 w-full rounded-t-[30px] bg-white/10 backdrop-blur-sm p-[20px] flex flex-col lg:flex-row gap-[20px] lg:gap-[10px] justify-center lg:justify-between text-white items-center drop-shadow-xl">
          <div className="w-full lg:w-auto text-sm lg:text-md text-center">
            Powered with CAI by ADAYield.io
          </div>
          <div className="flex flex-row justify-center gap-[20px]">
            <a
              href="https://twitter.com/realadayield"
              className="text-gray-200 hover:text-white"
            >
              <svg fill="#ffffff" width="30px" height="30px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.919 24.94c-2.548 0-4.921-.747-6.919-2.032a9.049 9.049 0 0 0 6.681-1.867 4.512 4.512 0 0 1-4.215-3.137c.276.054.559.082.848.082.412 0 .812-.056 1.193-.156a4.519 4.519 0 0 1-3.622-4.425v-.059a4.478 4.478 0 0 0 2.042.564 4.507 4.507 0 0 1-2.008-3.758c0-.824.225-1.602.612-2.268a12.811 12.811 0 0 0 9.303 4.715 4.517 4.517 0 0 1 7.692-4.115 9.107 9.107 0 0 0 2.866-1.094 4.542 4.542 0 0 1-1.983 2.498 9.08 9.08 0 0 0 2.592-.71 9.283 9.283 0 0 1-2.252 2.337c.008.193.014.388.014.583-.001 5.962-4.542 12.843-12.844 12.842" />
              </svg>
              <span className="sr-only">Twitter</span>
            </a>
            <a
              href="https://youtu.be/bOVPZvj-t7M"
              className="text-gray-200 hover:text-white"
            >
              <svg fill="#ffffff" width="30px" height="30px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.325 8.309s-2.655-.334-8.357-.334c-5.517 0-8.294.334-8.294.334A2.675 2.675 0 0 0 5 10.984v10.034a2.675 2.675 0 0 0 2.674 2.676s2.582.332 8.294.332c5.709 0 8.357-.332 8.357-.332A2.673 2.673 0 0 0 27 21.018V10.982a2.673 2.673 0 0 0-2.675-2.673zM13.061 19.975V12.03L20.195 16l-7.134 3.975z" />
              </svg>
              <span className="sr-only">Youtube</span>
            </a>
            <a
              href="https://discord.gg/fbXT3ywQ3x"
              className="text-gray-200 hover:text-white"
            >
              <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.59 5.88997C17.36 5.31997 16.05 4.89997 14.67 4.65997C14.5 4.95997 14.3 5.36997 14.17 5.69997C12.71 5.47997 11.26 5.47997 9.83001 5.69997C9.69001 5.36997 9.49001 4.95997 9.32001 4.65997C7.94001 4.89997 6.63001 5.31997 5.40001 5.88997C2.92001 9.62997 2.25001 13.28 2.58001 16.87C4.23001 18.1 5.82001 18.84 7.39001 19.33C7.78001 18.8 8.12001 18.23 8.42001 17.64C7.85001 17.43 7.31001 17.16 6.80001 16.85C6.94001 16.75 7.07001 16.64 7.20001 16.54C10.33 18 13.72 18 16.81 16.54C16.94 16.65 17.07 16.75 17.21 16.85C16.7 17.16 16.15 17.42 15.59 17.64C15.89 18.23 16.23 18.8 16.62 19.33C18.19 18.84 19.79 18.1 21.43 16.87C21.82 12.7 20.76 9.08997 18.61 5.88997H18.59ZM8.84001 14.67C7.90001 14.67 7.13001 13.8 7.13001 12.73C7.13001 11.66 7.88001 10.79 8.84001 10.79C9.80001 10.79 10.56 11.66 10.55 12.73C10.55 13.79 9.80001 14.67 8.84001 14.67ZM15.15 14.67C14.21 14.67 13.44 13.8 13.44 12.73C13.44 11.66 14.19 10.79 15.15 10.79C16.11 10.79 16.87 11.66 16.86 12.73C16.86 13.79 16.11 14.67 15.15 14.67Z" fill="#ffffff" />
              </svg>
              <span className="sr-only">Discord</span>
            </a>
            <a
              href="https://t.me/adayield"
              className="text-gray-200 hover:text-white"
            >
              <svg fill="#ffffff" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z" />
              </svg>
              <span className="sr-only">Telegram</span>
            </a>
            <a
              href="https://adayield.io/"
              className="text-gray-200 hover:text-white"
            >
              <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.982 19.61c.454-.7.909-1.6 1.236-2.755.755.29 1.273.636 1.591.909a8.182 8.182 0 0 1-2.864 1.845h.037v.001zm-8.8-1.855c.336-.273.845-.61 1.6-.91.336 1.164.773 2.064 1.236 2.764A8.2 8.2 0 0 1 6.2 17.755h-.018zm10.636-6.664c-.028-.81-.11-1.619-.245-2.418 1-.364 1.727-.8 2.236-1.2a8.136 8.136 0 0 1 1.282 3.618h-3.273zm-8.973-4.2a5.936 5.936 0 0 1-1.481-.8 8.2 8.2 0 0 1 2.654-1.7c-.427.636-.845 1.454-1.182 2.5h.01-.001zm7.137-2.5a8.145 8.145 0 0 1 2.654 1.7 6.01 6.01 0 0 1-1.481.8 9.58 9.58 0 0 0-1.182-2.5h.009zM14.8 9.118c.09.6.182 1.246.2 1.973H9c.027-.727.09-1.382.182-1.973 1.855.334 3.754.334 5.609 0h.009zM12 7.545c-.91 0-1.71-.072-2.39-.181.726-2.237 1.854-3.137 2.39-3.455.518.318 1.655 1.227 2.382 3.455A15.04 15.04 0 0 1 12 7.545zm-6.818-.072a8.03 8.03 0 0 0 2.245 1.2 18.368 18.368 0 0 0-.245 2.418h-3.31a8.13 8.13 0 0 1 1.319-3.618h-.01.001zm-1.3 5.436h3.3c.036.782.09 1.5.2 2.155a7.682 7.682 0 0 0-2.31 1.272 8.11 8.11 0 0 1-1.2-3.427h.01zM12 14.364c-1.09 0-2.027.09-2.845.236A16.91 16.91 0 0 1 9 12.91h6c-.027.608-.073 1.18-.145 1.69A15.388 15.388 0 0 0 12 14.355v.009zm0 5.727c-.545-.327-1.745-1.3-2.473-3.727A14.095 14.095 0 0 1 12 16.182c.955 0 1.773.063 2.482.182-.727 2.454-1.927 3.4-2.473 3.727H12zm6.927-3.764a7.634 7.634 0 0 0-2.309-1.272 17.95 17.95 0 0 0 .2-2.146h3.31a8.11 8.11 0 0 1-1.2 3.418h-.001zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z" fill="#ffffff" />
              </svg>
              <span className="sr-only">Website</span>
            </a>
          </div>
        </div>
      </nav>
      {showCardanoWallets && (
          <div className="fixed backdrop-filter backdrop-blur-lg bg-black/80 flex items-center justify-center overflow-auto z-50 inset-0 p-4">
              <div className="relative bg-[#1a1a1a] rounded-2xl shadow-2xl px-4 py-6 md:px-6 md:py-8 w-11/12 max-w-2xl mx-auto border border-white/10">
                  <div className="text-center mb-6">
                      <h1 className="text-white mb-2 font-bold text-2xl md:text-3xl tracking-tight">
                          Select Wallet
                      </h1>
                      <p className="text-white/60 text-sm">
                          Choose your preferred Cardano wallet to connect
                      </p>
                  </div>

                  <button
                      type="button"
                      onClick={hideWallets}
                      className="absolute text-2xl px-2.5 text-white/60 hover:text-white top-4 right-4 transition-colors"
                  >
                      <Close />
                  </button>

                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 px-2 py-4 max-h-[60vh] overflow-y-auto">
                      {Object.keys(cardanoWallets).map((item) => (
                          <div key={item} className="flex-shrink-0">
                              {(showCardanoWallets.filter((w_name) => w_name === item)).length > 0 ? (
                                  <button
                                      type="button"
                                      onClick={() => connect_CardanoWallet(item)}
                                      className="w-full py-4 px-3 bg-white/5 hover:bg-white/10 rounded-xl text-white border border-white/10 hover:border-white/20 transition-all duration-300 flex flex-col items-center"
                                  >
                                      <img
                                          src={cardanoWallets[item].image}
                                          alt={cardanoWallets[item].title}
                                          className="w-12 h-12 p-2 mb-2"
                                      />
                                      <span className="text-sm font-medium block text-center truncate w-full px-1">
                                          {cardanoWallets[item].title}
                                      </span>
                                      <div className="text-xs font-normal mt-1.5 text-green-400">
                                          <i className="fas fa-link mr-1" />
                                          enabled
                                      </div>
                                  </button>
                              ) : (
                                  <a 
                                      href={cardanoWallets[item].url} 
                                      target="_blank" 
                                      rel="noopener noreferrer" 
                                      className="block w-full"
                                  >
                                      <button
                                          type="button"
                                          className="w-full py-4 px-3 bg-white/5 hover:bg-white/10 rounded-xl text-white/60 border border-white/10 hover:border-white/20 transition-all duration-300 flex flex-col items-center"
                                      >
                                          <img
                                              src={cardanoWallets[item].image}
                                              alt={cardanoWallets[item].title}
                                              className="w-12 h-12 p-2 mb-2 opacity-60 blur-up lazyload"
                                              loading="lazy"
                                          />
                                          <span className="text-sm font-medium block text-center truncate w-full px-1">
                                              {cardanoWallets[item].title}
                                          </span>
                                          <div className="text-xs font-normal mt-1.5 text-blue-400">
                                              <i className="fas fa-download mr-1" />
                                              install
                                          </div>
                                      </button>
                                  </a>
                              )}
                          </div>
                      ))}
                  </div>
              </div>
          </div>
      )}
      {menuShow && (
          <div className="text-white fixed top-[70px] right-[20px] flex flex-col bg-white/10 backdrop-blur-sm">
              <div className="flex text-basic hover:cursor-pointer px-5 py-2 hover:bg-clip-text hover:text-transparent hover:bg-black/50" 
                   onClick={(e) => { navigate("/") }}>
                  Home
              </div>
              <div className="flex text-basic hover:cursor-pointer px-5 py-2 hover:bg-clip-text hover:text-transparent hover:bg-black/50" onClick={(e) => { state_wallet.connected ? navigate("/create") : toast("Connect Wallet First!") }}>Create</div>
              {state_wallet.connected && state_user.user ?
                  <a href={`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${state_user.user.Code}`}><div className="flex text-basic hover:cursor-pointer px-5 py-2 hover:bg-clip-text hover:text-transparent hover:bg-black/50">My StreamKey</div></a> :
                  <div className="flex text-basic hover:cursor-pointer px-5 py-2 hover:bg-clip-text hover:text-transparent hover:bg-black/50" onClick={(e) => { toast("Connect Wallet First!") }}>My StreamKey</div>
              }
              <div 
                  className="flex text-basic hover:cursor-pointer px-5 py-2 hover:bg-clip-text hover:text-transparent hover:bg-black/50"
                  onClick={() => {
                      setMenuShow(false);
                      setShowFAQ(true);
                  }}
              >
                  FAQ
              </div>
          </div>
      )}
      {showFAQ && (
          <div className="fixed inset-0 bg-black/80 backdrop-blur-lg flex items-center justify-center z-50 p-4 overflow-y-auto">
              <div className="relative bg-[#1a1a1a] rounded-2xl shadow-2xl w-full max-w-4xl border border-white/10">
                  <div className="sticky top-0 bg-[#1a1a1a] px-6 py-4 border-b border-white/10 flex justify-between items-center">
                      <h2 className="text-2xl md:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400">
                          Frequently Asked Questions
                      </h2>
                      <button
                          onClick={() => setShowFAQ(false)}
                          className="text-white/60 hover:text-white transition-colors p-2"
                      >
                          <Close />
                      </button>
                  </div>
                  
                  <div className="p-6 max-h-[70vh] overflow-y-auto">
                      <FAQ />
                  </div>
              </div>
          </div>
      )}
      {/* Floating Action Button */}
      <div className="fixed bottom-24 right-6 flex flex-col gap-2 z-[999]">
          <button
              onClick={handleCreateClick}
              className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-500 hover:to-purple-500 p-3 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 group backdrop-blur-sm"
              title="Create StreamKey"
          >
              <Add className="text-white text-2xl" />
          </button>
          {state_wallet.connected && state_user.user && (
              <a
                  href={`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${state_user.user.Code}`}
                  className="bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-500 hover:to-blue-500 p-3 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 backdrop-blur-sm"
                  title="My StreamKey"
              >
                  <Person className="text-white text-2xl" />
              </a>
          )}
      </div>
    </div>
  )
};

export default Layout;